<template>
  <div class="home homeXinke">
    <div class="swiper-container bannerHome">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_car_01.jpg" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_car_04.png" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_car_02.jpg" alt="">
        </div>
        <!-- <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner2.jpg" alt="">
        </div> -->
      </div>
      <div class="swiper-pagination swiper-pagination-banner"></div>
    </div>
    <div class="mian mainHome">
      

      <div class="xinkeSec01">
        <div class="wrap">
          <div class="xinkeHd">
            <div class="xinkeHdTitle">
              发展战略
            </div>
            <div class="xinkeHdSub">
              DEVELOPMENT STRATEGY
            </div>
            <div class="xinkeHdTxt">
              <p>
                企业自成立以来客户遍布甘肃省14个地州市及青海部分城市， 服务客户类型包含：出租、客运、公交、旅游客运、危险品 运输、物流运输、集团车队管理、金融车辆风控管理、定制客运、运管局、交通局等。 完成了以GPS/BDS定位为核心、车载远程视频智能调度管理为主的综合布局。秉承以质量求生存、以服务谋发展的经营理念，致力于满足各行各业对车辆综合调度管理的需求、满足政府监管部门对营 运车辆监管的需求，力争三年成为省内乃至西北地区有核心竞争力的GPS/BDS综合运营服务商。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="xinkeSec02">
        <div class="xinkeSec02Bg">
          <!-- <img src="../assets/img/banner4.jpg" alt=""> -->
          <video style="width: 100%;height: 400px;object-fit: fill;position: absolute;top:0;z-index: -100;" muted="" autoplay="autoplay" loop="loop">
            <source src="../assets/video/1.mp4" type="video/mp4">您的浏览器不支持 video 标签。
          </video>
        </div>
        <div class="wrap">
          <div class="xinkeHd xinkeHdFff">
            <div class="xinkeHdTitle">
              核心业务
            </div>
            <div class="xinkeHdSub">
              CORE BUSINESS
            </div>
          </div>
          <div class="xinkeSec02Box">
            <el-row :gutter="30">
              <el-col :span="8" v-for="(item, index) in businessList" :key="index">
                <div class="xinkeSec02Inner">
                  <div class="ico">
                    <img :src="businessListImg[index]" alt="">
                  </div>
                  <div class="title">
                    <a :href="`${apiName}/business?t=${index}`">{{item.name}}</a>
                  </div>
                  <div class="txt multi-line3" v-html="businessListTxt[index]">
                    <!-- {{ businessListTxt[index] | filtersText}} -->
                    <!-- {{businessListTxt[index]}} -->
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="homeMore">
              <a class="homeMoreBtn" :href="`${apiName}/business`">
                查看更多
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="xinkeSec03">
        <div class="wrap">
          <div class="xinkeHd">
            <div class="xinkeHdTitle">
              产品中心
            </div>
            <div class="xinkeHdSub">
              PRODUCTS
            </div>
          </div>
          <div class="xinkeSec03Box">
            <el-row :gutter="30">
              <el-col :span="8" v-for="(item, index) in soutionList" :key="index">
                <div class="xinkeSec03Inner" :class="{xinkeSec03Inner01: index == 0}">
                  <div class="xinkeSec03Bg"></div>
                  <div class="pic">
                    <img :src="soutionListImg[index]" /> 
                  </div>
                  <div class="xinkeSec03Title">
                    <div class="line lineTop"></div>
                    <div class="line lineBottom"></div>
                    <a :href="`${apiName}/competence?t=${index}`">{{item.name}}</a>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="homeMore">
              <a class="homeMoreBtn" :href="`${apiName}/competence`">
                查看更多
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap" style="padding-top: 70px; padding-bottom: 60px;">
        <!-- 要闻 -->
        <!-- <div class="home_pre clearfloat">
          <div class="home_pre_l fl"><span>要闻头条</span></div>
          <div class="inner">
            <el-carousel height="58px" direction="vertical" :interval="2500" indicator-position="none">
              <el-carousel-item v-for="item in newsTop" :key="item.id">
                <p class="single-line">
                  <a v-if="!item.is_link || item.is_link === 0" class="intro-btn" target="_blank" :href="`/swth/detail?id=${item.id}`">{{ item.title }}</a>
                  <a v-if="item.is_link === 1" target="_blank" class="intro-btn" :href="item.link_url">{{ item.title }}</a>
                </p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div> -->

        <div class="xinkeHd">
          <div class="xinkeHdTitle">
            新闻中心
          </div>
          <div class="xinkeHdSub">
            NEWS
          </div>
        </div>

        <!-- 新闻中心 -->
        <div class="homeNews" style="padding-top: 40px; padding-bottom: 30px;">
          <el-row :gutter="50">
            <el-col :span="12">
              <!-- <div class="swiper-container bannerHomeSec01">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in newsList.slice(0,6)" :key="index">
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">
                      <img :src="item.img" alt="">
                      <p class="single-line">{{item.title}}</p>
                    </a>
                    <a v-if="item.is_link === 1" target="_blank" class="intro-btn" :href="item.link_url">
                      <img :src="item.img" alt="">
                      <p class="single-line">{{item.title}}</p>
                    </a>
                  </div>
                </div>
                <div class="swiper-pagination swiper-pagination-sec01"></div>
              </div> -->
              <div class="homeNewsBox">
                <div class="homeNewsHd clearfloat" style="padding-bottom: 4px;">
                  <div class="homeNewsHdInner trans" :class="{active : activeHd == index}" v-for="(item, index) in newHdList01" :key="index">{{item.name}}</div>
                </div>
                <ul v-if="activeHd == 0" class="news_list news_list02">
                  <li v-for="(item,index) in newsRight01" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD')  }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="homeNewsBox">
                <div class="homeNewsHd clearfloat" style="padding-bottom: 4px;">
                  <div class="homeNewsHdInner trans" :class="{active : activeHd == index}" v-for="(item, index) in newHdList02" :key="index" @mouseenter="mouseenterNewsHd(index)">{{item.name}}</div>
                </div>
                <ul v-if="activeHd == 0" class="news_list news_list02">
                  <li v-for="(item,index) in newsRight02" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD')  }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="xinkeSec03">
        <div class="wrap">
          <div class="xinkeHd">
            <div class="xinkeHdTitle">
              监控平台
            </div>
            <div class="xinkeHdSub">
              MONITORING PLATFORM
            </div>
          </div>
          <div class="xinkeSec03Box">
            <ul class="partnerList clearfloat">
              <li v-for="(item, index) in listDataJkpt" :key="index">
                <a :href="item.link_url" target="_blank">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.img"
                    fit="cover"></el-image>
                  <div class="partnerListBg trans">
                    <p>
                      {{item.title}}
                    </p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
export default {
  name: 'home',
  data(){
    return {
      partnerList: [
        {
          name: '中国电信甘肃分公司中国电信甘肃分公司',
          img: require('../assets/img/partner_07.jpg'),
        },
        {
          name: '中国移动甘肃分公司',
          img: require('../assets/img/partner_01.png'),
        },
        {
          name: '中国联通甘肃分公司',
          img: require('../assets/img/partner_04.jpg'),
        },
        {
          name: '中国移动甘肃分公司二部',
          img: require('../assets/img/partner_05.jpg'),
        },
        {
          name: '中国电信甘肃分公司',
          img: require('../assets/img/partner_07.jpg'),
        },
        {
          name: '中国移动甘肃分公司',
          img: require('../assets/img/partner_01.png'),
        },
        {
          name: '中国联通甘肃分公司',
          img: require('../assets/img/partner_04.jpg'),
        },
        {
          name: '中国移动甘肃分公司二部',
          img: require('../assets/img/partner_05.jpg'),
        },
      ],
      businessListTxt: [],
      businessListImg: [
        require('../assets/img/ico_wulianwang.png'),
        require('../assets/img/ico_zhihuichengshi.png'),
        require('../assets/img/ico_wangluotuopu.png'),
        require('../assets/img/ico_jinrong.png'),
        require('../assets/img/ico_wqjk.png'),
        require('../assets/img/ico_zhihuijiaotong.png'),
      ],
      businessList: [
        // {
        //   title: '智能安防',
        //   img: require('../assets/img/ico_wulianwang.png'),
        //   des: '智能安防简介智能安防简，介智能安防简介智能安防简介智能安防简介智能安防简介智能安，防简介智能安防简介智能安防简介智能安防简介智能安，防简介智能安防简介智能安防简介',
        //   url: `${this.apiName}/business?t=0`
        // },
        // {
        //   title: '联网联控',
        //   img: require('../assets/img/ico_zhihuichengshi.png'),
        //   des: '联网联控简介联网联控简介联，网联控简介联网联控简介联网联控简介联网，联控简介联网联控简介联网联控简介联网联控简介联网联控简介联网联，控简介联网联控简介联网联控简介',
        //   url: `${this.apiName}/business?t=1`
        // },
        // {
        //   title: '通讯控制',
        //   img: require('../assets/img/ico_wangluotuopu.png'),
        //   des: '通讯控制简介通,讯控制简介通讯控制简介通讯控制简介通讯,控制简介通讯控制简介通讯控制简介通讯控制简介通讯控制简,介通讯控制简介通讯控制简介',
        //   url: `${this.apiName}/business?t=2`
        // },
        // {
        //   title: '车身控制',
        //   img: require('../assets/img/ico_jinrong.png'),
        //   des: '车身控制简介车身控制简介车，身控制简介车身控制简介车身控制简介车身控制简介车身控制简介车，身控制简介车身控制简介车身控制简介车身控制简介车身控制简介',
        //   url: `${this.apiName}/business?t=3`
        // },
        // {
        //   title: '尾气监控',
        //   img: require('../assets/img/ico_wqjk.png'),
        //   des: '尾气监控简介尾气监控，简介尾气监控简介尾气监控简介尾气监，控简介尾气监控简介',
        //   url: `${this.apiName}/business?t=4`
        // },
        // {
        //   title: '城市出租',
        //   img: require('../assets/img/ico_zhihuijiaotong.png'),
        //   des: '城市出租简介城市出租简介城市出租简，介城市出租简介城市出租简介城市出租简介',
        //   url: `${this.apiName}/business?t=5`
        // },
      ],
      soutionListImg: [
        require('../assets/img/cp_zhcl01.png'),
        require('../assets/img/cp_wqjc01.png'),
        require('../assets/img/cp_wy01.png'),
        require('../assets/img/cp_tbox01.png'),
        require('../assets/img/cp_bcm01.png')
      ],
      soutionList: [
        // {
        //   title: '风控终端',
        //   img: require('../assets/img/cp_zhcl01.png'),
        //   url: `${this.apiName}/competence?t=0`
        // },
        // {
        //   title: '尾气检测终端',
        //   img: require('../assets/img/cp_wqjc01.png'),
        //   url: `${this.apiName}/competence?t=1`
        // },
        // {
        //   title: '网约车终端',
        //   img: require('../assets/img/cp_wy01.png'),
        //   url: `${this.apiName}/competence?t=2`
        // },
        // {
        //   title: 'T-BOX',
        //   img: require('../assets/img/cp_tbox01.png'),
        //   url: `${this.apiName}/competence?t=3`
        // },
        // {
        //   title: 'BCM',
        //   img: require('../assets/img/cp_bcm01.png'),
        //   url: `${this.apiName}/competence?t=4`
        // },
      ],
      activeHd: 0,
      newHdList: [
        {
          name: '集团公司',
        },
        {
          name: '航投公司',
        },
        {
          name: '苏武公司',
        }
      ],
      newHdList01: [
        {
          name: '公司动态',
        },
      ],
      newHdList02: [
        {
          name: '通知公告',
        },
      ],
      listZfgs: [],
      newsTop: [],
      newsLeft: [],
      newsRight01: [],
      newsRight02: [],
      newsRight03: [],
      newsRight: [],
      newsList: [
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          update_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          create_time: '2021-05-14'
        },
      ],
      options01: [
        {
          name: '外交部',
          url: 'http://www.fmprc.gov.cn'
        },
        {
          name: '国防部',
          url: 'http://www.mod.gov.cn/'
        },
        {
          name: '发展改革委',
          url: 'http://www.ndrc.gov.cn/'
        },
        {
          name: '教育部',
          url: 'http://www.moe.edu.cn/'
        },
        {
          name: '科技部',
          url: 'http://www.most.gov.cn/'
        },
        {
          name: '中纪委',
          url: 'https://www.ccdi.gov.cn/'
        },
        {
          name: '工业和信息化部',
          url: 'http://www.miit.gov.cn/'
        },
        {
          name: '自然资源部',
          url: 'http://www.mnr.gov.cn/'
        },
        {
          name: '公安部',
          url: 'http://www.mps.gov.cn/'
        },
        {
          name: '民政部',
          url: 'http://www.mca.gov.cn'
        },
        {
          name: '司法部',
          url: 'http://www.moj.gov.cn'
        },
        {
          name: '财政部',
          url: 'http://www.mof.gov.cn'
        },
        {
          name: '人力资源社会保障部',
          url: 'http://www.mohrss.gov.cn'
        },
        {
          name: '生态环境部',
          url: 'http://www.mee.gov.cn'
        },
        {
          name: '住房城乡建设部',
          url: 'http://www.mohurd.gov.cn'
        },
        {
          name: '交通运输部',
          url: 'http://www.mot.gov.cn'
        },
        {
          name: '水利部',
          url: 'http://www.mwr.gov.cn/'
        },
        {
          name: '农业农村部',
          url: 'http://www.moa.gov.cn/'
        },
        {
          name: '商务部',
          url: 'http://www.mofcom.gov.cn'
        },
        {
          name: '文化旅游部',
          url: 'http://www.mct.gov.cn/'
        },
        {
          name: '国家卫生健康委员会',
          url: 'http://www.nhc.gov.cn'
        },
        {
          name: '应急管理部',
          url: 'http://www.chinasafety.gov.cn/'
        },
        {
          name: '人民银行',
          url: 'http://www.pbc.gov.cn'
        },
        {
          name: '审计署',
          url: 'http://www.audit.gov.cn/'
        },
        {
          name: '国资委',
          url: 'http://www.sasac.gov.cn/index.html'
        },
        {
          name: '海关总署',
          url: 'http://www.customs.gov.cn/publish/portal0/'
        },
        {
          name: '税务总局',
          url: 'http://www.chinatax.gov.cn/'
        },
        {
          name: '国家市场监督管理总局',
          url: 'http://www.aqsiq.gov.cn/'
        },
        {
          name: '国家广播电视总局',
          url: 'http://www.sapprft.gov.cn/'
        },
        {
          name: '体育总局',
          url: 'http://www.sport.gov.cn/'
        },
        {
          name: '统计局',
          url: 'http://www.stats.gov.cn/'
        },
        {
          name: '知识产权局',
          url: 'http://www.sipo.gov.cn/'
        },
        {
          name: '宗教局',
          url: 'http://www.sara.gov.cn/'
        },
        {
          name: '参事室',
          url: 'http://www.counsellor.gov.cn/'
        },
        {
          name: '国管局',
          url: 'http://www.ggj.gov.cn/'
        },
        {
          name: '版权局',
          url: 'http://www.ncac.gov.cn/'
        },
        {
          name: '法制办',
          url: 'http://www.chinalaw.gov.cn/'
        },
        {
          name: '国务院研究室',
          url: 'http://www.gov.cn/gjjg/2005-12/26/content_137261.htm'
        },
        {
          name: '台办',
          url: 'http://www.gwytb.gov.cn/'
        },
        {
          name: '新闻办',
          url: 'http://www.scio.gov.cn/'
        },
        {
          name: '新华社',
          url: 'http://www3.xinhuanet.com/'
        },
        {
          name: '中科院',
          url: 'http://www.cas.cn/'
        },
        {
          name: '社科院',
          url: 'http://cass.cssn.cn/'
        },
        {
          name: '工程院',
          url: 'http://www.cae.cn/'
        },
        {
          name: '发展研究中心',
          url: 'http://www.drc.gov.cn/'
        },
        {
          name: '中央党校',
          url: 'http://www.nsa.gov.cn/'
        },
        {
          name: '气象局',
          url: 'http://www.cma.gov.cn/'
        },
        {
          name: '地震局',
          url: 'http://www.cea.gov.cn/'
        },
        {
          name: '证监会',
          url: 'http://www.csrc.gov.cn/pub/newsite/'
        },
      ],
      value01: '政府及有关部门',
      value02: '集团及所属公司',
      value03: '综合网站',
      value04: '周边服务',
      // value05: '金融机构网站'
      businessImg01: [
        {
          url: require('../assets/img/business_img0101.jpg')
        },
        {
          url: require('../assets/img/business_img0201.jpg')
        },
        {
          url: require('../assets/img/business_img0301.jpg')
        },
        {
          url: require('../assets/img/business_img03_01.jpg')
        },
      ],
      businessImg02: [
        {
          url: require('../assets/img/business_img0201.jpg')
        },
        {
          url: require('../assets/img/business_img0101.jpg')
        },
        {
          url: require('../assets/img/business_img03_01.jpg')
        },
        {
          url: require('../assets/img/business_img0101.jpg')
        },
      ],
      businessImg03: [
        {
          url: require('../assets/img/business_img0301.jpg')
        },
        {
          url: require('../assets/img/business_img0201.jpg')
        },
        {
          url: require('../assets/img/business_img0101.jpg')
        },
        {
          url: require('../assets/img/business_img03_01.jpg')
        },
      ],
      businessImg04: [
        {
          url: require('../assets/img/business_img0401.jpg')
        },
        {
          url: require('../assets/img/business_img04.jpg')
        },
        {
          url: require('../assets/img/business_img0302.jpg')
        },
        {
          url: require('../assets/img/business_img0301.jpg')
        },
      ],
      newsBusiness01: [],
      newsBusiness02: [],
      newsBusiness03: [],
      newsBusiness04: [],
      businessNavList: [],
      listDataJkpt: [],
    }
  },
  mounted(){
    this.bannerInit()
    this.companyNews()
    this.companyNewsNotice()
    this.getSubNavBusiness()
    this.getSubNavProdu()
    this.getListJkpt()
  },
  filters:{
    filtersText(val){
        if (val != null && val != '') {
            let reg = /[\u4e00-\u9fa5]/g;
            let names = val.match(reg);
            val = names ? names.join('') : '';
            return val;
        } else return '';
    }
  },
  methods: {
    ToText(HTML) {
      var input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');  
    },
    // 公司新闻
    async companyNews() {
      var _this = this;
      // 公司新闻
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "1", page: 1, pageSize: 10});
      _this.newsRight01 = data.data.list;
    },
    // 通知公告
    async companyNewsNotice() {
      var _this = this;
      // 公司新闻
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "3", page: 1, pageSize: 10});
      _this.newsRight02 = data.data.list;
    },
    // 核心业务
    async getSubNavBusiness() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: 3});
      _this.businessNavList = data.data
      if (data.data.length) {
        data.data.forEach(line => {
          _this.getListBusiness(line.id)
        })
        _this.businessList = data.data.slice(0, 6)
        console.log('_this.businessList :>> ', _this.businessList);
      }
    },
    // 查列表
    async getListBusiness(id) {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: id, page: 1, pageSize: 10});
      _this.getDetailBusiness(data.data.list[0].id)
    },
    // 查详情
    async getDetailBusiness(id) {
      var _this = this;

      var { data } = await _this.$http.post( _this.apiUrl + "/news/getBackstageNewsDetail", { id: id });
      let txt = data.data.content
      // _this.businessListTxt.push(txt)
      _this.businessListTxt.push(_this.ToText(txt))
    },
    // 产品中心
    async getSubNavProdu() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: 4});
      _this.soutionList = data.data.slice(0, 5)
    },
    // 监控平台
    async getListJkpt() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: 23, page: 1, pageSize: 8});
      _this.listDataJkpt = data.data.list
    },
    // banner
    bannerInit(){
      new Swiper(".bannerHome", {
        effect : 'fade',
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-banner",
          type: 'fraction',
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 焦点图
    bannerHomeSec01(){
      var _this = this
      
      new Swiper(".bannerHomeSec01", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-sec01",
          clickable :true,
          renderBullet: function (index, className) {
            return '<div class="day ' + className + '"><div class="day">' + _this.newsList[index].create_time.slice(8, 10) + '</div><div class="time">' + _this.newsList[index].create_time.slice(0, 4) + '.' + _this.newsList[index].create_time.slice(5, 7) + '</div></div>';
          },
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
  }
}
</script>
<style scoped>
.bannerHome {
  height: 720px;
  position: relative;
}
.bannerHome .swiper-wrapper,
.bannerHome .swiper-slide,
.bannerHome img {
  height: 100%;
  -moz-transform: scale(1.0);
  -webkit-transform: scale(1.0);
  -o-transform: scale(1.0);
  transform: scale(1.0);
  -moz-transition: all 3s linear;
  -webkit-transition: all 3s linear;
  -o-transition: all 3s linear;
  transition: all 3s linear;
}
.bannerHome img {
  width: 100%;
}
.bannerHome .swiper-slide.swiper-slide-active img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.swiper-pagination-banner {
  color: #fff;
  font-size: 16px;
}
.swiper-pagination-current {
  font-size: 20px;
}
.secBox01 {
  padding: 60px 0;
  background-image: url(../assets/img/home_bg_sec01.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.secBox01Hd {
  margin-bottom: 30px;
  line-height: 48px;
}
.secBox01Title {
  font-size: 40px;
  color: #8A8A8A;
  text-transform: Uppercase;
}
.secBox01Txt {
  font-size: 30px;
  color: #535353;
}
.secBox01Tab {
  margin-bottom: 54px;
  text-align: center;
}
.secBox01Tab span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 18px;
  margin: 0 26px;
  font-size: 16px;
  color: #282828;
  background-color: #fff;
  cursor: pointer;
  border-radius: 20px;
}
.secBox01Tab span.active {
  color: #fff;
  background-color: #BC8F68;
}
.bannerHomeSec01 {
  height: 408px;
  position: relative;
}
.bannerHomeSec01 .swiper-wrapper,
.bannerHomeSec01 .swiper-slide,
.bannerHomeSec01 img {
  height: 100%;
}
.bannerHomeSec01 img {
  width: 100%;
  height: 100%;
}
.bannerHomeSec01 .swiper-slide {
  position: relative;
}
/* .bannerHomeSec01 p {
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 118px 0 12px;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
  text-align: left;
} */
.bannerHomeSec01 .swiper-pagination-bullet {
  width: 44px;
  height: 44px;
  text-align: center;
  border-radius: 0;
  background-color:rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.bannerHomeSec01 .swiper-pagination-bullet-active {
  background-color:#CE251F;
}
.bannerHomeSec01 .swiper-pagination {
  text-align: right;
  left: auto;
  right: 8px;
}
/* 友情链接 */
.sec_zfgs_list a {
    display: block;
    text-align: center;
    line-height: 34px;
    border: 1px solid #d5d5d5;
    margin-bottom: 6px;
}

.sec_zfgs_list a:hover {
    border-color: #2b78c6;
    color: #fff;
    background-color: #2b78c6;
}
.homeNewsHdInner {
  display: inline-block;
  padding: 0 20px;
  font-size: 16px;
  background-color: #f0f0f0;
  color: #333;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  margin-right: 1px;
}
.homeNewsHdInner.active {
  background-color: #2b78c6;
  color: #fff;
}
.homeXinke {
  padding-top: 100px;
}
</style>